import React, { useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from "firebase/auth";
import GoogleSignInButton from '../GoogleSignInButton/GoogleSignInButton.js';
import OrBanner from '../OrBanner/OrBanner.js';
import ActionButton from '../Buttons/ActionButton/ActionButton.js';
import { setDoc, getDoc, doc, getFirestore } from 'firebase/firestore';
import { useUserContext } from '../../Contexts/UserContext/UserContext.js';

// This component is a form that allows the user to register with an email and password
const RegisterForm = ({ showLoginBox, email, setEmail, password, setPassword, confirmPassword, setConfirmPassword, passwordMatch, setPasswordMatch, handlePageChange }) => {
    const { createUser } = useUserContext();
    // These functions are called when the user types in the email and password fields
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    // This function is called when the user types in the confirm password field
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        setPasswordMatch(event.target.value === password);
    }

    // This function is called when the user clicks the "Register" button
    // It creates a new user with the email and password the user entered
    const handleSignup = () => {
        if (!passwordMatch) {
            alert("Passwords do not match!")
            return
        }
        const createdUser = {
            email: email,
            password: password,
        }
        createUser(createdUser);
        showLoginBox();
    };

    return (
        <div className="login-box">
            <form className="login-form" onSubmit={(e) => e.preventDefault()}>
                <h1 className="login-title">Register</h1>
                <hr className="separator-hr" />

                <GoogleSignInButton buttonText={'Register with Google'} createUser={createUser} />

                <OrBanner />

                {/* INPUTS */}
                <div className="login-inputs">
                    {/* Using autofocus this way is bad for accessibility */}
                    {/* https://blog.maisie.ink/react-ref-autofocus/ */}
                    <input type="text" placeholder="Email Address" className="login-input" onChange={handleEmailChange} value={email} autoFocus />
                    <input type="password" placeholder="Password" className="login-input" onChange={handlePasswordChange} value={password} />
                    <input type="password" placeholder="Confirm Password" className="login-input" onChange={handleConfirmPasswordChange} value={confirmPassword} />
                    {!passwordMatch && <p>Passwords do not match!</p>}
                </div>
                {/* - - - */}

                {/* BUTTON */}
                {passwordMatch ? <ActionButton buttonText={'Register'} buttonFunction={handleSignup} /> : <ActionButton buttonText={'Register'} buttonFunction={handleSignup} disabled={true} />}
                {/* - - - */}
            </form>

            {/* SHOW LOGIN */}
            <div className="register">
                <p className="register-text">Already have an account?</p>
                <button className="register-link" onClick={showLoginBox}>Login</button>
            </div>
            {/* - - - */}
        </div>
    );
};

export default RegisterForm;
