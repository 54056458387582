import React, { useState } from 'react'
import ActionButton from '../../Elements/Buttons/ActionButton/ActionButton';
import './InitialInfoInput.css';
import { useUserContext } from '../../Contexts/UserContext/UserContext.js';

const InitialInfoInput = () => {

    const [name, setName] = useState('');
    const [birthday, setBirthday] = useState('');

    const handleNameChange = (e) => setName(e.target.value);
    const handleBirthdayChange = (e) => setBirthday(e.target.value);

    const { updateUser, userState } = useUserContext();


    const handleSetName = () => {
        const userUid = userState.profile.uid;
        updateUser(userUid, { name: name, birthday: birthday });
    };

    return (
        <div className="main-initial-info-content">
            <div className="login-box">
                <h1 className="login-title">Additional Info</h1>
                <p className="register-text">What should we call you?</p>
                {/* Name input */}
                <div className="login-inputs">
                    <input type="text" placeholder="Name" className="login-input" onChange={handleNameChange} value={name} />
                </div>
                {/* - - - */}
                <br />
                <p className="register-text">When is your birthday?</p>
                {/* Name input */}
                <div className="login-inputs">
                    <input type="date" placeholder="Birthday" className="login-input" onChange={handleBirthdayChange} value={birthday} />
                </div>
                {/* - - - */}
                <br />

                <ActionButton buttonText={'Submit'} buttonFunction={handleSetName} />


            </div>
        </div>
    )
}

export default InitialInfoInput