import React, { useState, useEffect } from 'react';
import { firestore } from '../../../../firebaseinit';
import { collection, doc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import '../NewNom/NewNom.css';
import ActionButton from '../../Buttons/ActionButton/ActionButton';
import RadioSelector from '../../FormElements/RadioSelector/RadioSelector';
import CheckboxSelector from '../../FormElements/CheckboxSelector/CheckboxSelector';
import { useNomsDispatch, useNomsState } from '../../../Contexts/Noms/NomsContext.js';
import { useModal } from '../../../Contexts/ModalContext/ModalContext.js';

const EditNom = ({ nom, handleNomChange, hasActionButton = true, handleFullEditedNomChange }) => {
    const [nomName, setNomName] = useState(nom.name);
    const [nomTime, setNomTime] = useState(nom.time);
    const [nomType, setNomType] = useState(nom.type);
    const [nomFeeling, setNomFeeling] = useState(nom.feeling);
    const [nomTags, setNomTags] = useState(nom.tags);
    const [currentUser, setCurrentUser] = useState(getAuth().currentUser);

    const noms = useNomsState();
    const { handleNomUpdate } = useNomsDispatch();
    const { closeModal } = useModal();

    const handleInputChange = (event, setStateFunc) => {
        setStateFunc(event.target.value);
    };

    const prepareNom = () => {
        const updatedNom = {
            id: nom.id,
            name: nomName,
            time: nomTime,
            type: nomType,
            createdAt: nom.createdAt, // this can stay as is since it's not being changed
            updatedAt: new Date()
        };
        return updatedNom;
    };

    useEffect(() => {
        if (handleFullEditedNomChange !== undefined) {
            const updatedNom = prepareNom();
            handleFullEditedNomChange(updatedNom);
        }
    }, [nomName, nomTime, nomType]);

    const handleSubmit = async () => {
        if (nomTags === undefined) {
            setNomTags([]);
        }
        const updatedNom = {
            id: nom.id,
            name: nomName,
            time: nomTime,
            type: nomType,
            createdAt: nom.createdAt, // this can stay as is since it's not being changed
            updatedAt: new Date()
        };
        handleNomUpdate(nom.id, updatedNom);
        console.log("Updating nom: " + updatedNom.id + " from EditNom.js");
        // handleNomChange(updatedNom);
        closeModal();
    };

    return (
        <div className="new-nom-content">
            <div className="new-nom-header">
                Edit Nom
            </div>
            <hr className="new-nom-hr" />

            <div className="new-nom-body">
                {/* Input field for Nom Name */}
                <div className="new-nom-body-row">
                    <div className="new-nom-body-row-label">
                        Nom Name
                    </div>
                    <div className="new-nom-body-row-input">
                        <input
                            type="text"
                            className="new-nom-text-input"
                            value={nomName}
                            onChange={(e) => handleInputChange(e, setNomName)}
                        />
                    </div>
                </div>

                {/* Input field for Time of Consumption */}
                <div className="new-nom-body-row">
                    <div className="new-nom-body-row-label">
                        Time of Consumption
                    </div>
                    <div className="new-nom-body-row-input">
                        <input
                            type="time"
                            className="new-nom-time-input"
                            value={nomTime}
                            onChange={(e) => handleInputChange(e, setNomTime)}
                        />
                    </div>
                </div>
                {/* Radio selectors for Type and Feeling */}
                <RadioSelector
                    label="Type"
                    options={[
                        { value: 'food', label: 'Food', icon: 'lunch_dining', color: 'var(--clr-nom-food)' },
                        { value: 'drink', label: 'Drink', icon: 'local_drink', color: 'var(--clr-nom-drink)' }
                    ]}
                    value={nomType}
                    onSelectionChange={(selectedType) => setNomType(selectedType)}
                />
                <hr className="new-nom-hr" />
                {hasActionButton ?
                    <div className="new-nom-footer">
                        <ActionButton buttonText={'Update Nom'} buttonFunction={handleSubmit} />
                    </div> : ''}
            </div>
        </div>
    );
};

export default EditNom;
