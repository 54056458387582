import React, { useState, useRef, useEffect } from 'react'
import './Pagination.css'
import { useSpring, animated } from '@react-spring/web'

const Pagination = ({ pages }) => {
    const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
    const [contentHeight, setContentHeight] = useState('350px');
    // console.log("Received pages in Pagination:", pages);
    // console.log("Type of pages:", typeof pages);
    // console.log("Length of pages:", pages.length);

    const contentRef = useRef(null);

    if (!pages || pages.length === 0) {
        throw new Error("Pagination component requires a non-empty array of pages");
    }

    const isSelected = (index) => {
        return index + 1 === currentPaginationPage;
    }

    useEffect(() => {
        if (contentRef.current) {
            const height = contentRef.current.offsetHeight;
            setContentHeight(`${height}px`);
        }
    }, [currentPaginationPage]);

    // Animate the height of pagination-content
    const springs = useSpring({
        height: contentHeight,
        from: { height: '350px' },
        config: { tension: 500, friction: 20 }
    });


    return (
        <div className="pagination-main">
            {/* Display the content of the current page */}
            <animated.div style={springs}>
                <div className="pagination-content" ref={contentRef}>
                    {pages[currentPaginationPage - 1]}
                </div>
            </animated.div>

            {/* Display page numbers */}
            {pages.length > 1 ?
                <div className="pagination-display">
                    {pages.map((_, index) => (
                        <div className="pagination-object" key={index}>
                            <input type="radio"
                                name="paginationSelection"
                                id={`pagination-${index}`} // Adding unique id for each input
                                className={`pagination-page ${isSelected(index) ? 'selected' : ''}`}
                                onChange={() => setCurrentPaginationPage(index + 1)} // Replacing onClick with onChange
                                checked={isSelected(index)}
                            />
                            <label htmlFor={`pagination-${index}`}>{index + 1}</label>
                        </div>
                    ))}
                </div> : ''
            }
        </div >
    );
}

export default Pagination