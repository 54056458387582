import { getAuth } from "firebase/auth";
import { getDoc, doc, getFirestore } from "firebase/firestore";

export function addSuffixToDay(day) {
    let suffix = 'th'
    if (day === 1 || day === 21 || day === 31) {
        suffix = 'st'
    } else if (day === 2 || day === 22) {
        suffix = 'nd'
    } else if (day === 3 || day === 23) {
        suffix = 'rd'
    }
    return day + suffix
}

export function convertToTwelveHour(timeStr) {
    // Splitting the input string based on the colon
    const [hourStr, minuteStr] = timeStr.split(':');
    let hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);
    let suffix = 'AM';

    if (hour > 12) {
        hour -= 12;
        suffix = 'PM';
    } else if (hour === 0) {  // Handle the edge case where time is "00:xx"
        hour = 12;
    }

    // Returning the formatted 12-hour time
    return `${hour}:${minute < 10 ? '0' + minute : minute} ${suffix}`;
}

// This function is used to parse the createdAt field of a nom from Firestore 
// into a JavaScript Date object that can be used to compare dates.
export function parseCustomDate(input) {
    // Check if the input is a Firestore timestamp
    if (input && typeof input.seconds === 'number') {
        // Convert Firestore timestamp to JavaScript Date
        return new Date(input.seconds * 1000); // Convert seconds to milliseconds
    }
    else if (input instanceof Date) {  // Check if the input is a JavaScript Date object
        return input;
    }
    else if (typeof input === 'string') {
        const [day, monthName, year, , time, timezone] = input.split(' ');
        const [hours, minutes, seconds] = time.split(':');
        const utcOffset = -parseInt(timezone.split('UTC-')[1]);

        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const monthNumber = monthNames.indexOf(monthName);

        const date = new Date(Date.UTC(year, monthNumber, day, hours - utcOffset, minutes, seconds));

        return date;
    }
    else {
        console.error('parseCustomDate was called with an unexpected value:', input);
        return new Date(); // Default to current date
    }
}

// Function to get the start of a given week
export function getStartOfWeek(date) {
    const dayOfWeek = date.getDay();
    const startOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - dayOfWeek);
    return startOfWeek;
}

export function getWeek(date) {
    const days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
    // Get start of the week
    const currentWeekStart = getStartOfWeek(date);

    // Array to store the day items for the week
    const dayItems = [];
    // Start at the beginning of the week
    let day = new Date(currentWeekStart.getFullYear(), currentWeekStart.getMonth(), currentWeekStart.getDate());

    // For each day of the week...
    for (let i = 0; i < 7; i++) {
        const day = new Date(currentWeekStart.getFullYear(), currentWeekStart.getMonth(), currentWeekStart.getDate() + i);
        dayItems.push({ day });
    }

    return dayItems;
}

export const getCurrentUser = async () => {
    const db = getFirestore();
    const auth = getAuth();
    if (!auth.currentUser) return null;
    const cUserRef = doc(db, "users", auth.currentUser.uid);
    const cUser = await getDoc(cUserRef);
    if (!cUser.exists()) return null;
    return cUser.data();
}

export const checkForName = async () => {
    let currentUser = await getCurrentUser();
    if (!currentUser) {
        console.log("checkForName returning false because currentUser is null");
        return false;
    }

    // Check if the 'name' attribute exists and is not an empty string
    if (!currentUser.name || currentUser.name.trim() === "") {
        return false;
    }

    return true;
}
