import React from 'react'
import './PageProfileContent.css'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import '../../../App.css'
import { getWeek, parseCustomDate } from '../../../NomFunctions.js'
import { useNomsState, getAverageFeeling } from '../../Contexts/Noms/NomsContext';

const PageProfileContent = () => {
    const noms = useNomsState();
    const week = getWeek(new Date());
    let nomsOfTheWeek = [];

    noms.forEach((nom) => {
        const parsedDate = parseCustomDate(nom.createdAt);

        // Normalize parsedDate to remove the time component
        parsedDate.setHours(0, 0, 0, 0);

        week.forEach((day) => {
            // Normalize day.day to remove the time component
            day.day.setHours(0, 0, 0, 0);

            if (parsedDate.getTime() === day.day.getTime()) {
                nomsOfTheWeek.push(nom);
            }
        });
    });

    // console.log("Noms of the week: ", nomsOfTheWeek);


    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Feelings this Week',
            },
        },
    };

    const labels = ['Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Feeling',
                data: [1, 2, 6, 4, 5, 2, 7],
                borderColor: '#459373',
                backgroundColor: '#5dc69c',
            }
        ],
    };
    return (
        <div className='main-profile-content'>
            <div className="profile-header-bubble profile-bubble">
                <h1>Your profile</h1>
                <div className="profile-user-info profile-bubble">
                    <h2>User info</h2>
                    <div className="profile-user-info-bubble">
                        <h3>User info goes here</h3>
                    </div>
                </div>
            </div>
            {/* - User info - */}
            <div className="profile-content">
                {/* - Feeling chart - */}
                <div className="profile-feeling-chart profile-bubble">
                    <h2>Average Feelings this Week</h2>
                    <div className="profile-feeling-chart-bubble">
                        <Line options={options} data={data} />
                    </div>
                </div>
                {/* - User stats - */}
                {/* - User settings - */}
            </div>
        </div>
    )
}

export default PageProfileContent