import React from 'react'

const NomSymptom = ({ color, icon, text }) => {
    return (
        <div className={"nom-feeling " + color}>
            <div className="nom-feeling-icon">
                {icon ?
                    <span className="material-symbols-outlined">
                        {icon}
                    </span> : ''}{text}
            </div>
        </div>
    )
}

export default NomSymptom