import React, { useState } from 'react';
import Navbar from '../../Elements/Navbar/Navbar';
import Footer from '../../Elements/Footer/Footer';
import Content from './Content/Content';
import { getAuth } from 'firebase/auth';
import { useNomsState } from '../../Contexts/Noms/NomsContext.js';

const Home = ({ logoutUser }) => {
    const date = new Date();
    const [selectedDay, setSelectedDay] = useState(date.getDate());
    const noms = useNomsState(); // Get the noms state

    const resetDaySelection = () => {
        setSelectedDay(date.getDate());
    }

    const user = getAuth().currentUser;


    return (
        <div style={{ height: "100%" }}>
            {/* NAVBAR */}
            <Navbar logoutUser={logoutUser} />

            {/* MAIN CONTENT */}
            <Content user={user.displayName} selectedDay={selectedDay} setSelectedDay={setSelectedDay} noms={noms} />

            {/* FOOTER */}
            <Footer resetDaySelection={resetDaySelection} />
        </div>
    );
}

export default Home;
