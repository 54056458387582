import React, { createContext, useReducer, useContext } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFirestore, setDoc, doc, deleteDoc, getDoc, collection, addDoc, updateDoc } from 'firebase/firestore';
import { usePageContext } from '../PageContext/PageContext';

const UserContext = createContext();

const initialUserState = {
    profile: null,
    isLoggedIn: false,
    error: null,
};

const userReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, isLoggedIn: true, profile: action.payload, error: null };
        case 'LOGOUT':
            return { ...state, isLoggedIn: false, profile: null, error: null };
        case 'SET_PROFILE':
            return { ...state, profile: { ...state.profile, ...action.payload } };
        case 'SET_ERROR':
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

export const UserProvider = ({ children }) => {

    const { handlePageChange } = usePageContext();
    const [userState, dispatch] = useReducer(userReducer, initialUserState);
    const auth = getAuth();
    const db = getFirestore();

    const loginUser = async (credentials) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, credentials.email, credentials.password);
            const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
            console.log("User logged in successfully!");
            dispatch({ type: 'LOGIN', payload: userDoc.data() });
            // handlePageChange('home');
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    };

    const logoutUser = async () => {
        try {
            await signOut(auth);
            dispatch({ type: 'LOGOUT' });
            handlePageChange('login');
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    };

    const createUser = async (userData) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, userData.email, userData.password);
            const userRef = doc(db, 'users', userCredential.user.uid);
            await setDoc(userRef, {
                email: userData.email,
                uid: userCredential.user.uid,
                name: '',
                birthday: '',
            });
            dispatch({ type: 'LOGIN', payload: userCredential.user });
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    };

    const deleteUser = async (uid) => {
        try {
            // Deleting from Firestore
            await deleteDoc(doc(db, 'users', uid));

            // Deleting from Firebase Authentication
            const user = auth.currentUser;
            if (user) {
                await user.delete();
            }

            dispatch({ type: 'LOGOUT' });
            handlePageChange('login');
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    };

    const updateUser = async (uid, userData) => {
        // Convert userData from an object to a JSON string
        const userDataString = JSON.stringify(userData);
        try {
            console.log("Attempting user update: " + uid + " " + userDataString);
            try {
                await updateDoc(doc(db, 'users', uid), userData);
            } catch (error) {
                console.log("Error updating user: " + error);
            }
            dispatch({ type: 'SET_PROFILE', payload: userData });
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    };

    const setUser = async (uid) => {
        try {
            const userDoc = await getDoc(doc(db, 'users', uid));
            dispatch({ type: 'SET_PROFILE', payload: userDoc.data() });
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    };

    return (
        <UserContext.Provider value={{ userState, dispatch, loginUser, logoutUser, createUser, deleteUser, updateUser, setUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => useContext(UserContext);
