import React from 'react';
import './Modal.css';
import { useModal } from '../../Contexts/ModalContext/ModalContext.js';

const Modal = () => {
    // console.log("Modal open? " + isModalOpen);
    const { isModalOpen, modalContent, closeModal } = useModal();

    if (!isModalOpen) return null;

    return (
        <div className={`modal ${isModalOpen ? '' : 'modal-inactive'}`}>
            <div className="modal-bg"></div>
            <div className="modal-content">
                <button className="modal-close" onClick={closeModal}>
                    <span className="material-symbols-outlined">
                        close
                    </span>
                </button>
                <div className="modal-icon">
                    <span className="material-symbols-outlined">
                        local_dining
                    </span>
                </div>
                <div className="modal-body">
                    {modalContent}
                </div>
            </div>
        </div>
    )
}

export default Modal