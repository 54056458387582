import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { doc, deleteDoc, setDoc, collection, addDoc, getDocs } from 'firebase/firestore';

const NomsStateContext = createContext();
const NomsDispatchContext = createContext();

const nomsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_NOMS':
            return action.payload;
        case 'ADD_NOM':
            return [...state, action.payload];
        case 'EDIT_NOM':
            return state.map(nom => nom.id === action.payload.id ? action.payload : nom);
        case 'DELETE_NOM':
            return state.filter(nom => nom.id !== action.payload);
        case 'SORT_NOMS':
            return getSortedNoms(state, action.payload.sortOption);
        default:
            throw new Error(`Unknown action: ${action.type}`);
    }
};

// Dispatch not working
const fetchNomsFromFirestore = async (firestore, currentUser, dispatch) => {
    try {
        const nomsCollection = collection(firestore, 'users', currentUser.uid, 'noms');
        const nomsSnapshot = await getDocs(nomsCollection);
        const fetchedNoms = nomsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        dispatch({ type: 'SET_NOMS', payload: fetchedNoms });
    } catch (error) {
        console.error("Error fetching noms: ", error);
        alert("There was an error fetching the noms. Please try again.");
    }
};

const getSortedNoms = (nomsToSort, sortOption) => {
    let sortedNoms = [...nomsToSort];
    switch (sortOption) {
        case 'time':
            sortedNoms.sort((a, b) => {
                const [aHour, aMinute] = a.time.split(':').map(Number);
                const [bHour, bMinute] = b.time.split(':').map(Number);
                const aTotalMinutes = aHour * 60 + aMinute;
                const bTotalMinutes = bHour * 60 + bMinute;
                return bTotalMinutes - aTotalMinutes;
            });
            break;
        case 'feeling':
            sortedNoms.sort((a, b) => a.feeling.localeCompare(b.feeling));
            break;
        case 'type':
            sortedNoms.sort((a, b) => a.type.localeCompare(b.type));
            break;
        default:
            break;
    }
    return sortedNoms;
};

export const getAverageFeeling = (noms) => {
    // Calculate the average feeling by counting how many of each feeling there are and dividing by the total number of noms to reveal what the average feeling is
    const feelings = noms.map(nom => nom.feeling);
    const feelingCounts = feelings.reduce((counts, feeling) => {
        counts[feeling] = (counts[feeling] || 0) + 1;
        return counts;
    }, {});
    const feelingEntries = Object.entries(feelingCounts);
    const feelingEntriesSorted = feelingEntries.sort((a, b) => b[1] - a[1]);
    const averageFeeling = feelingEntriesSorted[0][0];
    // console.log("Average feeling: ", averageFeeling);
    return averageFeeling;
};

export const getNomSuggestions = (noms) => {
    // This function will return an array of noms that the user has entered
    // multiple times, sorted by the number of times they've been entered
    // in descending order
    const nomCounts = noms.reduce((counts, nom) => {
        counts[nom.name] = (counts[nom.name] || 0) + 1;
        return counts;
    }, {});
    const nomEntries = Object.entries(nomCounts);
    const nomEntriesSorted = nomEntries.sort((a, b) => b[1] - a[1]);
    let nomSuggestions = nomEntriesSorted.map(nom => nom[0]);
    // console.log("Nom suggestions: ", nomSuggestions);
    // Trim the array to only include the top 4 suggestions
    nomSuggestions = nomSuggestions.slice(0, 4);
    let nomSuggestionObjects = [];
    nomSuggestions.forEach(nom => {
        nomSuggestionObjects.push({
            name: nom,
            type: noms.find(nomObject => nomObject.name === nom).type
        });
    });
    return nomSuggestionObjects;
};

export const getNomTypeIcons = (noms) => {
    // This function will return an array of span elements that contain
    // the icon for the type of nom that corresponds to the nom
    // (food or drink)
    const nomTypeIcons = [];
    noms.forEach(nom => {
        if (nom.type === 'food') {
            nomTypeIcons.push(<span className="material-symbols-outlined nom-type-icon">lunch_dining</span>);
        } else {
            nomTypeIcons.push(<span className="material-symbols-outlined nom-type-icon">local_drink</span>);
        }
    }
    );
    return nomTypeIcons;
    // const nomTypeIcons = noms.map(nom => {
    //     if (nom.type === 'food') {
    //         return <span className="material-symbols-outlined nom-type-icon">lunch_dining</span>;
    //     } else {
    //         return <span className="material-symbols-outlined nom-type-icon">local_drink</span>;
    //     }
    // });
};

export const NomsProvider = ({ children, currentUser, firestore }) => {
    const [noms, dispatch] = useReducer(nomsReducer, []);
    useEffect(() => {
        fetchNomsFromFirestore(firestore, currentUser, dispatch);
    }, []);

    const handleNomDelete = async (nomId) => {
        console.log("Attempting to delete nom: ", nomId);
        try {
            const nomRef = doc(firestore, 'users', currentUser.uid, 'noms', nomId);
            await deleteDoc(nomRef);
            dispatch({ type: 'DELETE_NOM', payload: nomId });
        } catch (error) {
            console.error("Error deleting nom: ", error);
            alert("There was an error deleting the Nom. Please try again.");
        }
    }

    const handleNomAdd = async (nomData) => {
        try {
            const nomRef = await addDoc(collection(firestore, 'users', currentUser.uid, 'noms'), nomData);
            const newNom = { id: nomRef.id, ...nomData };
            dispatch({ type: 'ADD_NOM', payload: newNom });
        } catch (error) {
            console.error("Error adding nom: ", error);
            alert("There was an error adding the Nom. Please try again.");
        }
    }

    const handleNomUpdate = async (nomId, updatedData) => {
        try {
            // console.log("Attempting to update nom: ", nomId, updatedData + " from NomsContext.js");
            const nomRef = doc(firestore, 'users', currentUser.uid, 'noms', nomId);
            await setDoc(nomRef, updatedData, { merge: true });
            const updatedNom = { id: nomId, ...updatedData };
            dispatch({ type: 'EDIT_NOM', payload: updatedNom });
        } catch (error) {
            console.error("Error updating nom: ", error);
            alert("There was an error updating the Nom. Please try again.");
        }
    }

    return (
        <NomsStateContext.Provider value={noms}>
            <NomsDispatchContext.Provider value={{ dispatch, handleNomDelete, handleNomAdd, handleNomUpdate }}>
                {children}
            </NomsDispatchContext.Provider>
        </NomsStateContext.Provider>
    );
};

export const useNomsState = () => {
    const context = useContext(NomsStateContext);
    if (context === undefined) {
        throw new Error('useNomsState must be used within a NomsProvider');
    }
    return context;
};

export const useNomsDispatch = () => {
    const context = useContext(NomsDispatchContext);
    if (context === undefined) {
        throw new Error('useNomsDispatch must be used within a NomsProvider');
    }
    return context;
};
