import React, { useEffect, useState } from 'react';
import './DayCarousel.css';
import DayCarouselItem from './DayCarouselItem';
import NavigationArrow from '../Buttons/NavigationArrow/NavigationArrow';


const days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
// State for selected day
const date = new Date();


const DayCarousel = ({ selectedDay, updateSelectedDay }) => {
    useEffect(() => {
        // console.log("Selected day in DayCarousel: " + selectedDay);
    }, [selectedDay]);

    // Get today's date
    const today = new Date();
    // Initialize state for the start of the current week
    const [currentWeekStart, setCurrentWeekStart] = useState(getStartOfWeek(today));
    // Initialize state for the current month
    const [currentMonth, setCurrentMonth] = useState(today.getMonth());

    // Function to get the start of a given week
    function getStartOfWeek(date) {
        const dayOfWeek = date.getDay();
        const startOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - dayOfWeek);
        return startOfWeek;
    }

    // Function to get the end of a given week
    function getEndOfWeek(date) {
        const startOfWeek = getStartOfWeek(date);
        const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6);
        return endOfWeek;
    }

    // Function to handle clicks on the left navigation arrow
    function handleLeftArrowClick() {
        // Compute the start of the previous week
        const previousWeekStart = new Date(currentWeekStart.getFullYear(), currentWeekStart.getMonth(), currentWeekStart.getDate() - 7);
        // If the previous week is in a different month, update the current month
        if (previousWeekStart.getMonth() !== currentMonth) {
            setCurrentMonth(previousWeekStart.getMonth());
        }
        // Update the start of the current week
        setCurrentWeekStart(previousWeekStart);
    }

    // Function to handle clicks on the right navigation arrow
    function handleRightArrowClick() {
        // Compute the start of the next week
        const nextWeekStart = new Date(currentWeekStart.getFullYear(), currentWeekStart.getMonth(), currentWeekStart.getDate() + 7);
        // If the next week is in a different month, update the current month
        if (nextWeekStart.getMonth() !== currentMonth) {
            setCurrentMonth(nextWeekStart.getMonth());
        }
        // Update the start of the current week
        setCurrentWeekStart(nextWeekStart);
    }

    // Array to store the day items for the week
    const dayItems = [];
    // Start at the beginning of the week
    let day = new Date(currentWeekStart.getFullYear(), currentWeekStart.getMonth(), currentWeekStart.getDate());
    // Get the end of the week
    const endOfWeek = getEndOfWeek(currentWeekStart);
    // For each day of the week...
    for (let i = 0; i < 7; i++) {
        const day = new Date(currentWeekStart.getFullYear(), currentWeekStart.getMonth(), currentWeekStart.getDate() + i);
        const dayNum = day.getDate();
        const dayOfWeek = days[day.getDay()];
        // If this is the selected day, create a DayCarouselItem with today=true
        if (day.getDate() === selectedDay && day.getMonth() === today.getMonth()) {
            dayItems.push(<DayCarouselItem key={dayNum} day={dayOfWeek} dayNum={dayNum} today={true} currentMonth={day.getMonth() === today.getMonth()} />);
        } else {
            // Otherwise, create a DayCarouselItem with today=false and an onClick handler to update the selected day
            dayItems.push(<DayCarouselItem key={dayNum} day={dayOfWeek} dayNum={dayNum} today={false} onClick={() => updateSelectedDay(dayNum)} currentMonth={day.getMonth() === today.getMonth()} />);
        }
    }

    return (
        <div>
            <div className="day-carousel">
                {/* Left arrow */}
                <NavigationArrow direction={'left'} onClick={handleLeftArrowClick} />
                {dayItems}
                {/* Right arrow */}
                <NavigationArrow direction={'right'} onClick={handleRightArrowClick} />
            </div>
        </div>
    )
}

export default DayCarousel
