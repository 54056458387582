import './Navbar.css';

const Navbar = (props) => {
    const { logoutUser } = props;
    return (
        <div className="navbar-main">
            <div>
            </div>
            <div className="brand-logo">
                {/* Favicon.png from public folder */}
                <img className="nav-icon" src="favicon.png" alt="NomTracker" />
                NomTracker
            </div>
            <div className="logout-button" onClick={logoutUser}>
                <span className="material-symbols-outlined">
                    logout
                </span>
            </div>
        </div>
    );
};

export default Navbar;