import React from 'react'
import './OrBanner.css'

const OrBanner = () => {
    return (
        <div className="login-or">
            <div className="login-or-banner">
                <hr className="login-hr" />
                <div className="login-or-text">OR</div>
            </div>
        </div>
    )
}

export default OrBanner