// Import necessary dependencies and components
import React, { useState, useEffect } from 'react';
import './Login.css';
import LoginForm from '../../Elements/LoginForm/LoginForm';
import RegisterForm from '../../Elements/RegisterForm/RegisterForm';
import NameInputForm from '../../Elements/RegisterForm/NameInputForm';
import { getCurrentUser, checkForName } from '../../../NomFunctions.js';
import { getAuth } from 'firebase/auth';
import { usePageContext } from '../../Contexts/PageContext/PageContext.js';
import { useUserContext } from '../../Contexts/UserContext/UserContext.js';

const Login = () => {

    // State management for UI & user data
    const [isRegisterBoxVisible, setRegisterBoxVisible] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // PageContext
    const { handlePageChange } = usePageContext();

    // Function to toggle visibility of the register box
    const showRegisterBox = () => {
        setRegisterBoxVisible(true);
        setEmail('');
        setPassword('');
        setConfirmPassword('');
    };

    // Function to toggle visibility back to the login box
    const showLoginBox = () => {
        setRegisterBoxVisible(false);
        setEmail('');
        setPassword('');
        setConfirmPassword('');
    };

    // Decide the content to show based on the user's status
    const pageContent = () => {
        if (!isRegisterBoxVisible) {
            return (<LoginForm showRegisterBox={showRegisterBox} email={email} setEmail={setEmail} password={password} setPassword={setPassword} handlePageChange={handlePageChange} />);
        } else {
            return (<RegisterForm showLoginBox={showLoginBox} email={email} setEmail={setEmail} password={password} setPassword={setPassword} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} passwordMatch={passwordMatch} setPasswordMatch={setPasswordMatch} handlePageChange={handlePageChange} />);
        }
    }

    // Render the component UI
    return (
        <div className="login-screen">
            <div className="login-box-full">
                {/* Display the logo */}
                <div className="login-logo">
                    <img src="favicon.png" alt="logo" className="login-logo-img" />
                    NomTracker
                </div>
                {/* Render the content based on user's status */}
                {pageContent()}
            </div>
        </div>
    );
};

export default Login;
