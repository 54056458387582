import React, { useState } from 'react';
import './RadioSelector.css';

const RadioSelector = ({ label, options, onSelectionChange, value }) => {
    const radioRefs = options.reduce((refs, option) => {
        refs[option.value] = React.createRef();
        return refs;
    }, {});

    const handleDivClick = (option) => {
        radioRefs[option].current.click();
    };

    return (
        <div className="radio-selector-row">
            <div className="radio-selector-row-label">
                {label}
            </div>
            <div className="radio-selector-row-input">
                {options.map(option => (
                    <div
                        key={option.value}
                        className={`radio-selector-option radio-selector-option-${option.value} 
                                 ${value === option.value ? 'selected' : ''}`}
                        style={{ backgroundColor: value === option.value ? option.color : '#00000035' }}
                        onClick={() => handleDivClick(option.value)}
                    >
                        <input
                            ref={radioRefs[option.value]}
                            type="radio"
                            name={`${label.toLowerCase()}-type`}
                            value={option.value}
                            className="radio-selector-input"
                            onChange={() => onSelectionChange(option.value)}
                            checked={value === option.value}
                        />
                        <span className="material-symbols-outlined radio-selector-icon">{option.icon}</span>
                        <label htmlFor={option.value}>{option.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RadioSelector;