import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import '../NewNom/NewNom.css';
import ActionButton from '../../Buttons/ActionButton/ActionButton';
import RadioSelector from '../../FormElements/RadioSelector/RadioSelector';
import CheckboxSelector from '../../FormElements/CheckboxSelector/CheckboxSelector';
import { useModal } from '../../../Contexts/ModalContext/ModalContext.js';

const EditNomFeeling = ({ nom, handleNomChange, hasActionButton = true, handleFullEditedNomChange }) => {
    const [nomName, setNomName] = useState(nom.name);
    const [nomTime, setNomTime] = useState(nom.time);
    const [nomType, setNomType] = useState(nom.type);
    const [nomFeeling, setNomFeeling] = useState(nom.feeling);
    const [nomTags, setNomTags] = useState(nom.tags);
    const [currentUser, setCurrentUser] = useState(getAuth().currentUser);

    const { closeModal } = useModal();

    const handleInputChange = (event, setStateFunc) => {
        setStateFunc(event.target.value);
    };

    const prepareNom = () => {
        const updatedNom = {
            feeling: nomFeeling,
            tags: nomTags,
            createdAt: nom.createdAt, // this can stay as is since it's not being changed
            updatedAt: new Date()
        };
        return updatedNom;
    };

    useEffect(() => {
        if (handleFullEditedNomChange !== undefined) {
            const updatedNom = prepareNom();
            handleFullEditedNomChange(updatedNom);
        }
    }, [nomFeeling, nomTags]);

    const handleSubmit = async () => {
        let tags = nomTags;
        if (tags === undefined || tags === null || tags.length === 0) {
            setNomTags([]);
            tags = [];
        }
        const updatedNom = {
            id: nom.id,
            name: nom.name,
            time: nom.time,
            type: nom.type,
            feeling: nomFeeling,
            tags: tags,
            createdAt: nom.createdAt, // this can stay as is since it's not being changed
            updatedAt: new Date()
        };
        // console.log("Updating nom: " + updatedNom.id + " from EditNomFeeling.js");
        handleNomChange(updatedNom);
        closeModal();
    };

    return (
        <div className="new-nom-content">
            <div className="new-nom-header">
                Edit Nom Feeling
            </div>
            <hr className="new-nom-hr" />

            <div className="new-nom-body">
                {/* Radio selectors for Feeling */}
                <RadioSelector
                    label="Feeling"
                    options={[
                        { value: 'great', label: 'Great', icon: 'mood', color: 'var(--clr-nom-great)' },
                        { value: 'good', label: 'Good', icon: 'sentiment_satisfied', color: 'var(--clr-nom-good)' },
                        { value: 'meh', label: 'Meh', icon: 'sentiment_neutral', color: 'var(--clr-nom-meh)' },
                        { value: 'bad', label: 'Bad', icon: 'sentiment_dissatisfied', color: 'var(--clr-nom-bad)' }
                    ]}
                    value={nomFeeling}
                    onSelectionChange={(selectedFeeling) => setNomFeeling(selectedFeeling)}
                />
                {/* Checkbox selector for ailments */}
                <CheckboxSelector
                    label="Ailments"
                    options={[
                        { value: 'Headache', label: 'Headache' },
                        { value: 'Nausea', label: 'Nausea' },
                        { value: 'Cramps', label: 'Cramps' },
                        { value: 'Indigestion', label: 'Indigestion' },
                        { value: 'Heartburn', label: 'Heartburn' },
                        { value: 'Bloated', label: 'Bloated' },
                        { value: 'Dizzy', label: 'Dizzy' },
                        { value: 'Dehydrated', label: 'Dehydrated' },
                        { value: 'Diarrhea', label: 'Diarrhea' },
                        { value: 'Other', label: 'Other' }
                    ]}
                    value={nomTags}
                    onSelectionChange={(selectedTags) => setNomTags(selectedTags)}
                />

                <hr className="new-nom-hr" />

                {hasActionButton ?
                    <div className="new-nom-footer">
                        <ActionButton buttonText={'Update Nom Feeling'} buttonFunction={handleSubmit} />
                    </div> : ''}
            </div>
        </div>
    );
};

export default EditNomFeeling;
