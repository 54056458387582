import React from 'react';
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import GoogleSignInButton from '../GoogleSignInButton/GoogleSignInButton';
import OrBanner from '../OrBanner/OrBanner';
import ActionButton from '../Buttons/ActionButton/ActionButton';
import { useUserContext } from '../../Contexts/UserContext/UserContext';

// This component is a form that allows the user to sign in with an email and password
const LoginForm = ({ showRegisterBox, email, setEmail, password, setPassword, handlePageChange }) => {
    const { loginUser, setUser, userState } = useUserContext();
    // These functions are called when the user types in the email/password input fields

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    // This function is called when the user clicks the "Login" button
    const handleSignIn = () => {
        console.log("Attempting sign in with credentials: " + email + " " + password);
        const credentials = {
            email: email,
            password: password,
        }
        loginUser(credentials);
    };

    return (
        <div className="login-box">
            <form className="login-form" onSubmit={(e) => e.preventDefault()}>
                <h1 className="login-title">Login</h1>
                <hr className="separator-hr" />

                {/* GOOGLE SIGN IN BUTTON */}
                <GoogleSignInButton buttonText={'Sign in with Google'} handlePageChange={handlePageChange} setUser={setUser} />
                {/* - - - */}

                {/* OR BANNER */}
                <OrBanner />
                {/* - - - */}

                {/* INPUTS */}
                <div className="login-inputs">
                    {/* Using autofocus this way is bad for accessibility */}
                    {/* https://blog.maisie.ink/react-ref-autofocus/ */}
                    <input id="email-input" type="text" placeholder="Email Address" className="login-input" onChange={handleEmailChange} value={email} autoFocus />
                    <input type="password" placeholder="Password" className="login-input" onChange={handlePasswordChange} value={password} />
                    <div className="forgot-password">
                        <a href="#" className="forgot-password-link"><s>Forgot password?</s></a>
                    </div>
                </div>
                {/* - - - */}

                {/* BUTTON */}
                <ActionButton buttonText={'Login'} buttonFunction={handleSignIn} />
                {/* - - - */}
            </form>

            {/* SHOW REGISTRATION */}
            <div className="register">
                <p className="register-text">Don't have an account?</p>
                <button className="register-link" onClick={showRegisterBox}>Register</button>
            </div>
            {/* - - - */}
        </div>
    );
};

export default LoginForm;
