import React, { useState, useEffect } from 'react';
import Home from './Home/Home.js';
import Login from './Login/Login.js';
import UserProfile from './UserProfile/UserProfile.js';
import { firestore } from '../../firebaseinit';
import { NomsProvider } from '../Contexts/Noms/NomsContext.js';
import { PageProvider, usePageContext } from '../Contexts/PageContext/PageContext.js';
import { getDoc, doc, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useUserContext } from '../Contexts/UserContext/UserContext.js';
import InitialInfoInput from './UserProfile/InitialInfoInput.js';
import { ModalProvider } from '../Contexts/ModalContext/ModalContext.js';
import Modal from '../Elements/Modal/Modal.js';

const AppController = () => {
    const [user, setUser] = useState(null);
    const { currentPage, handlePageChange } = usePageContext();
    const { updateUser, logoutUser, userState } = useUserContext();

    // This needs to be removed and replaced with UserContext functionality
    const updateUserContent = (user) => {
        updateUser(user);
        setUser(user);
    }

    // This needs to be removed and replaced with UserContext functionality
    const logoutUserContent = () => {
        logoutUser();
        setUser(null);
        handlePageChange("login");
    }

    useEffect(() => {
        console.log("Checking to see if a user is logged in...")
        if (userState.profile != null) {
            console.log("User is logged in!")
            // Change this to switch to the name registration screen if the user doesn't have a name
            if (userState.profile.name == null) {
                handlePageChange("onboarding");
            }
            else {
                handlePageChange("home");
            }
        }
        else {
            console.log("User is not logged in!");
        }
    }, [userState.profile]);



    const renderCurrentPage = () => {
        switch (currentPage) {
            case "home":
                return (
                    <ModalProvider>
                        <NomsProvider currentUser={userState.profile} firestore={firestore}>
                            <Modal />
                            <Home logoutUser={logoutUser} />
                        </NomsProvider>
                    </ModalProvider>
                );
            case "login":
                return <Login updateUser={updateUserContent} currentUser={userState.profile} />;
            case "profile":
                return <NomsProvider currentUser={userState.profile} firestore={firestore}>
                    <UserProfile logoutUser={logoutUserContent} /></NomsProvider>;
            case "onboarding":
                return <InitialInfoInput updateUser={updateUser} />;
            default:
                return <div>Page not found</div>;
        }
    }

    return (
        renderCurrentPage()
    )
}

export default AppController