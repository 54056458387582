import React from 'react'
import './Nom.css'
import NomSymptom from './NomSymptom.js'
import { useNomsDispatch } from '../../Contexts/Noms/NomsContext.js';
import { convertToTwelveHour } from '../../../NomFunctions.js';
import NomActionButton from '../Buttons/NomActionButton/NomActionButton.js';

const Nom = ({ nom, handleEditNom, handleEditNomFeeling }) => {
    const { handleNomDelete } = useNomsDispatch();
    // Destructure the nom object
    const nomTitle = nom.name;
    const nomType = nom.type;
    let nomTime = convertToTwelveHour(nom.time);
    const nomFeeling = nom.feeling;
    const nomSymptoms = nom.tags;

    const nomTypeIconClass = 'nom-type-icon-' + nomType;
    let nomFeelingIcon = '';
    let nomFeelingText = '';
    let nomFeelingColor = '';
    let nomSymptomComponents = [];
    // Set the icon for feeling based on nomFeeling
    switch (nomFeeling) {
        case 'bad':
            nomFeelingIcon = 'sentiment_dissatisfied';
            nomFeelingText = 'Bad';
            nomFeelingColor = 'nom-feeling-bad';
            break;
        case 'meh':
            nomFeelingIcon = 'sentiment_neutral';
            nomFeelingText = 'Meh';
            nomFeelingColor = 'nom-feeling-meh';
            break;
        case 'good':
            nomFeelingIcon = 'sentiment_satisfied';
            nomFeelingText = 'Good';
            nomFeelingColor = 'nom-feeling-good';
            break;
        case 'great':
            nomFeelingIcon = 'mood';
            nomFeelingText = 'Great';
            nomFeelingColor = 'nom-feeling-great';
            break;
        default:
            nomFeelingIcon = '';
            nomFeelingText = '';
            nomFeelingColor = '';
    }

    if (nomSymptoms != null) {
        for (let i = 0; i < nomSymptoms.length; i++) {
            if (nomSymptoms[i] !== '' && nomSymptoms[i] !== undefined) {
                // console.log("Nom symptom: ", nomSymptoms[i]);
                nomSymptomComponents.push(<NomSymptom key={i} color={'nom-feeling-secondary'} text={nomSymptoms[i]} />);
            }
        }
    }

    function checkUpdateStatus() {
        if (nomFeelingText !== '') {
            return 'updated';
        } else {
            return 'not-updated';
        }
    }

    if (nomTime.includes("NaN")) {
        nomTime = '_ _ : _ _';
    }

    return (
        <>
            <div className={"nom-main " + checkUpdateStatus()}>
                <div className="nom-header">
                    <div className="nom-type-header">
                        <div className="nom-base-info">
                            <div className="nom-title">
                                <span className={'material-symbols-outlined nom-type-icon ' + nomTypeIconClass}>
                                    {nomType === 'food' ? 'lunch_dining' : 'local_drink'}
                                </span>
                                <h1>{nomTitle}</h1>
                            </div>
                            <span className="nom-time-info">
                                <span className="material-symbols-outlined">
                                    schedule
                                </span>{nomTime}
                            </span>
                        </div>
                    </div>
                </div>
                <hr className="nom-separator" />
                <div className="nom-body">
                    <div className="nom-feelings">
                        {/* If there is no nomFeelingText, show the action button to add a feeling */}
                        {nomFeelingText !== '' ?
                            <NomSymptom color={nomFeelingColor} icon={nomFeelingIcon} text={nomFeelingText} /> :
                            <NomActionButton buttonTextIcon={'add_circle'} buttonText="Add Feeling" buttonColorClass="nom-add-feeling" buttonFunction={handleEditNomFeeling} />}
                        {nomSymptomComponents}
                    </div>
                    <div className="nom-actions">
                        <div className="nom-edit-icon nom-icon">
                            <span className="material-symbols-outlined" onClick={handleEditNom}>
                                edit
                            </span>
                        </div>
                        <div className="nom-delete-icon nom-icon" onClick={() => handleNomDelete(nom.id)}>
                            <span className="material-symbols-outlined">
                                delete
                            </span>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Nom