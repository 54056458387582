import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDevlyiRjdVTPxpDG5xnJnvz0Wzy_I9TAA",
    authDomain: "nomtracker-ba90e.firebaseapp.com",
    projectId: "nomtracker-ba90e",
    storageBucket: "nomtracker-ba90e.appspot.com",
    messagingSenderId: "429321146186",
    appId: "1:429321146186:web:730fa82d86884a08627bb3"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);

