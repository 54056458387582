import React from 'react'
import './ActionButton.css'

const ActionButton = ({ buttonFunction, buttonText, disabled = false }) => {
    if (disabled) {
        return (
            <button className="action-button action-button-disabled" disabled>{buttonText}</button>
        )
    }
    return (
        <button className="action-button" onClick={buttonFunction}>{buttonText}</button>
    )
}

export default ActionButton