import React, { useState } from 'react';
import Nom from '../Nom/Nom.js';
import './NomList.css';
import { parseCustomDate } from '../../../NomFunctions.js';
import { useNomsState } from '../../Contexts/Noms/NomsContext.js';
import { useModal } from '../../Contexts/ModalContext/ModalContext.js';

const NomList = ({ noms, date, selectedDay, handleEditNom, handleEditNomFeeling }) => {
    const [sortOption, setSortOption] = useState('time');
    const [filterName, setFilterName] = useState('');

    const { openModal } = useModal();

    const handleSortChange = (event) => {
        setSortOption(event.target.value);
    }

    const handleFilterNameChange = (event) => {
        setFilterName(event.target.value);
    }

    const dynamicSort = (a, b) => {
        if (a.time === "") {
            a.time = "__:__";
        }
        if (b.time === "") {
            b.time = "__:__";
        }
        switch (sortOption) {
            case 'time':
                // Check and prioritize "__:__" format
                if (a.time === "__:__" && b.time !== "__:__") return -1;
                if (b.time === "__:__" && a.time !== "__:__") return 1;
                if (a.time === "__:__" && b.time === "__:__") {
                    if (a.createdAt > b.createdAt) return -1;
                    if (a.createdAt < b.createdAt) return 1;
                    if (a.createdAt === b.createdAt) return 0;
                }

                // Compare times in descending order
                return b.time.localeCompare(a.time);

            case 'feeling':
                return (a.feeling || '').localeCompare(b.feeling || '');

            case 'type':
                return (a.type || '').localeCompare(b.type || '');

            default:
                return 0;
        }
    };

    const selectedDayDate = new Date(date.getFullYear(), date.getMonth(), selectedDay);

    return (
        <div className="nom-container">
            <div className='sortByRow'>
                <div className="sortBlock">
                    <div className="sortLabel">Sort by:</div>
                    <select name="sortBy" id="sortBy" onChange={handleSortChange} defaultValue='time'>
                        <option className="sortByOption" value="time">Time</option> {/* Fixed 'svalue' to 'value' */}
                        <option className="sortByOption" value="feeling">Feeling</option> {/* Fixed 'svalue' to 'value' */}
                        <option className="sortByOption" value="type">Type</option> {/* Fixed 'svalue' to 'value' */}
                    </select>
                </div>
                <input type="text" className="filterInput" placeholder="Filter by name" onChange={handleFilterNameChange} />
            </div>
            <div className="newNomRow">
                <div className="newNomPlus" onClick={openModal}>+</div>
            </div>
            {useNomsState()
                .filter(nom => {
                    const nomDate = parseCustomDate(nom.createdAt);
                    return nomDate.toDateString() === selectedDayDate.toDateString() && (!filterName || nom.name.toLowerCase().includes(filterName.toLowerCase()));
                })
                .sort(dynamicSort)
                .map(nom => (
                    <Nom
                        key={nom.id}
                        nom={nom}
                        handleEditNom={() => handleEditNom(nom)}
                        handleEditNomFeeling={() => handleEditNomFeeling(nom)}
                    />
                ))}
        </div>
    )
}

export default NomList;
