// Styling
import './Content.css';

// React imports
import { useEffect, useRef } from 'react';

// Nom Components
import DayCarousel from '../../../Elements/DayCarousel/DayCarousel';
import Modal from '../../../Elements/Modal/Modal';
import NewNom from '../../../Elements/ModalContents/NewNom/NewNom';
import EditNom from '../../../Elements/ModalContents/EditNom/EditNom';
import EditNomFeeling from '../../../Elements/ModalContents/EditNomFeeling/EditNomFeeling';
import NomList from '../../../Elements/NomList/NomList';
import Pagination from '../../../Elements/Pagination/Pagination';
import ActionButton from '../../../Elements/Buttons/ActionButton/ActionButton';

// Function imports
import { addSuffixToDay } from '../../../../NomFunctions.js';
import { useState } from 'react';
import { useNomsDispatch } from '../../../Contexts/Noms/NomsContext.js'; // Import the dispatch hook

// Context imports
import { useUserContext } from '../../../Contexts/UserContext/UserContext.js';
import { useModal } from '../../../Contexts/ModalContext/ModalContext.js';

// Getting today's date for initial state
const date = new Date();

const Content = ({ user, selectedDay, setSelectedDay }) => {
    const [editingNom, setEditingNom] = useState(null);
    const [isEditingNomFeeling, setIsEditingNomFeeling] = useState(false);
    const { dispatch, handleNomUpdate } = useNomsDispatch();
    const [fullEditedNom, setFullEditedNom] = useState({});
    const { userState } = useUserContext();

    const { openModal, closeModal, setModalContent, isModalOpen } = useModal();

    const fullEditedNomRef = useRef(fullEditedNom);

    // This useEffect resets the editingNom and fullEditedNom states when the modal is closed
    useEffect(() => {
        if (!isModalOpen) {
            setEditingNom(null);
            setFullEditedNom({});
        }
    }, [isModalOpen]);

    const handleNewNom = (nom) => {
        dispatch({ type: 'ADD_NOM', payload: nom });
    }

    const handleNomChange = (updatedNom) => {
        handleNomUpdate(updatedNom.id, updatedNom);
        setEditingNom(null);
    };

    const handleEditNom = (nom) => {
        setIsEditingNomFeeling(false);
        setEditingNom(nom);
        setFullEditedNom(prepareFullNom(nom));
        openModal();
    }

    const handleEditNomFeeling = (nom) => {
        setIsEditingNomFeeling(true);
        setEditingNom(nom);
        setFullEditedNom(prepareFullNom(nom));
        openModal();
    }

    const prepareFullNom = (nom) => {
        if (nom.tags === undefined || nom.tags === null) {
            nom.tags = [];
        }
        if (nom.feeling === undefined || nom.feeling === null) {
            nom.feeling = '';
        }
        if (nom.time === undefined || nom.time === null) {
            nom.time = '';
        }
        if (nom.type === undefined || nom.type === null) {
            nom.type = '';
        }
        const updatedNom = {
            id: nom.id,
            name: nom.name,
            time: nom.time,
            type: nom.type,
            feeling: nom.feeling,
            tags: nom.tags,
            createdAt: nom.createdAt, // this can stay as is since it's not being changed
            updatedAt: new Date()
        };
        return updatedNom;
    };

    const handleFullEditedNomChange = (updatedNom) => {
        // Update fullEditedNom state and merge updatedNom with fullEditedNom, overwriting any existing properties
        setFullEditedNom(prevState => ({ ...prevState, ...updatedNom }));
    }

    const handleUpdateFullNom = () => {
        handleNomUpdate(fullEditedNomRef.current.id, fullEditedNomRef.current);
        setEditingNom(null);
        setFullEditedNom({});
        closeModal();
    }

    useEffect(() => {
        fullEditedNomRef.current = fullEditedNom;
    }, [fullEditedNom]);

    const dayWithSuffix = addSuffixToDay(selectedDay);
    const monthName = date.toLocaleString('default', { month: 'long' });
    const today = `${monthName} ${dayWithSuffix}`;
    let modalContent;
    useEffect(() => {
        let pages = [];

        if (editingNom) {
            if (isEditingNomFeeling) {
                pages.push(<EditNomFeeling nom={editingNom} handleNomChange={handleNomChange} />);
                setModalContent(<Pagination pages={pages} />);
            } else {
                pages.push(<EditNom nom={editingNom} hasActionButton={false} handleFullEditedNomChange={handleFullEditedNomChange} />);
                pages.push(<EditNomFeeling nom={editingNom} hasActionButton={false} handleFullEditedNomChange={handleFullEditedNomChange} />);
                setModalContent(
                    <>
                        <Pagination pages={pages} />
                        <ActionButton buttonText={'Update Nom'} buttonFunction={handleUpdateFullNom} />
                    </>
                );
            }
        } else if (isModalOpen) {
            pages.push(<NewNom />);
            setModalContent(<Pagination pages={pages} />);
        } else {
            // Reset modal content if needed
            setModalContent(null);
        }
    }, [editingNom, isEditingNomFeeling, isModalOpen]);

    // TODO: Modal needs to be raised to a higher parent (maybe AppController) 
    // TODO: so that it can have a higher z-index than the content, and subsequently
    // TODO: the header and footer. This will allow the modal to be displayed over 
    // TODO: the header and footer, and not just the content.

    // TODO: Maybe there needs to be a ModalContext that can be used to set the modal content and open/close state???
    return (
        <div className="main-content">
            <div className="content-greeting">
                <span className="content-greeting-pre">Hello, </span>
                <span className="content-greeting-name">{userState.profile.name}!</span>
            </div>
            <DayCarousel selectedDay={selectedDay} updateSelectedDay={setSelectedDay} />
            <div className="content-header">
                <div className="content-header-bg"></div>
                <div className="content-header-text">
                    <h1>Noms of the day</h1>
                    <span> - {today} - </span>
                </div>
            </div>
            <NomList
                date={date}
                selectedDay={selectedDay}
                handleEditNom={handleEditNom}
                handleEditNomFeeling={handleEditNomFeeling}
                key={Date.now()}
            />
        </div>
    );
};

export default Content;
