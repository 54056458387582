import React from 'react'
import Navbar from '../../Elements/Navbar/Navbar';
import Footer from '../../Elements/Footer/Footer';
import PageProfileContent from './PageProfileContent';

const UserProfile = ({ logoutUser, resetDaySelection, handlePageChange }) => {
    return (
        <div style={{ height: '100%' }}>
            <Navbar logoutUser={logoutUser} />
            <PageProfileContent />
            <Footer resetDaySelection={resetDaySelection} handlePageChange={handlePageChange} />
        </div>
    )
}

export default UserProfile