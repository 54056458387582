import React from 'react';
import './CheckboxSelector.css';

const CheckboxSelector = ({ label, options, onSelectionChange, value = [] }) => {

    const handleSelectionChange = (event, option) => {
        event.preventDefault(); // prevent default checkbox behavior
        let updatedSelections = [...value];

        if (updatedSelections.includes(option)) {
            updatedSelections = updatedSelections.filter((item) => item !== option);
        } else {
            updatedSelections.push(option);
        }

        onSelectionChange(updatedSelections);
    };

    return (
        <div className="checkbox-selector-row">
            <div className="checkbox-selector-row-label">
                {label}
            </div>
            <div className="checkbox-selector-row-input">
                {options.map(option => (
                    <div
                        key={option.value}
                        className={`checkbox-selector-option checkbox-selector-option-${option.value} 
                                     ${value.includes(option.value) ? 'selected' : ''}`}
                        onClick={(event) => handleSelectionChange(event, option.value)}>
                        <input
                            type="checkbox"
                            name={`${label.toLowerCase()}-type`}
                            value={option.value}
                            className="checkbox-selector-input"
                            checked={value.includes(option.value)}
                            readOnly
                        />
                        <label htmlFor={option.value}>{option.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CheckboxSelector;
