// Core libraries
import React, { useState, useEffect } from 'react';

// Styling
import './NewNom.css';

// Custom components
import ActionButton from '../../Buttons/ActionButton/ActionButton';
import RadioSelector from '../../FormElements/RadioSelector/RadioSelector';
import { useNomsDispatch, useNomsState, getNomSuggestions, getNomTypeIcons } from '../../../Contexts/Noms/NomsContext.js';
import { useModal } from '../../../Contexts/ModalContext/ModalContext.js';
// import CheckboxSelector from '../../FormElements/CheckboxSelector/CheckboxSelector';

const NewNom = () => {
    const { handleNomAdd } = useNomsDispatch();
    // State setup for individual nom properties
    const [nomName, setNomName] = useState('');
    const [nomTime, setNomTime] = useState('');
    const [nomType, setNomType] = useState('');
    // const [nomFeeling, setNomFeeling] = useState('');
    // const [nomTags, setNomTags] = useState([]);
    const [formKey, setFormKey] = useState(0); // Used to reset the form on submission
    const [formSubmitted, setFormSubmitted] = useState(false); // Used to reset the form on submission

    const { closeModal } = useModal();

    // Useeffect for form key to reset the form on submission
    useEffect(() => {
        // Functional update here because React complains about the formKey being a dependency :/
        setFormKey((formKey) => formKey + 1);
        setFormSubmitted(false);
    }, [formSubmitted]);

    // Function to update input states
    const handleInputChange = (event, setStateFunc) => {
        setStateFunc(event.target.value);
    };

    // Function to handle the form submission
    const handleSubmit = async () => {
        // console.log("Creating new nom: " + nomName + " at " + nomTime + ". Nom type: " + nomType);
        // Constructing the nom object :)
        const nom = {
            name: nomName,
            time: nomTime,
            type: nomType,
            createdAt: new Date()
        };

        // Add the nom to the database, reset the form and close the modal
        handleNomAdd(nom);
        resetNewNomForm();
        closeModal();
    };

    const resetNewNomForm = () => {
        setNomName('');
        setNomTime('');
        setNomType('');
        setFormSubmitted(true);
    };

    const suggestedNoms = getNomSuggestions(useNomsState());

    const nomTypeIcons = getNomTypeIcons(suggestedNoms);

    const selectSuggestedNom = (nom) => {
        console.log("Selected suggested nom: ", nom.name + " " + nom.type);
        setNomName(nom.name);
        setNomType(nom.type);
    };

    // Rendered JSX
    return (
        <div className="new-nom-content" key={formKey}>
            <div className="new-nom-header">
                Create new Nom
            </div>
            <hr className="new-nom-hr" />
            {/* Nom suggestions here */}
            <h4>Recent Noms</h4>
            <div className="new-nom-suggestions">
                {suggestedNoms.map((nom, index) => {
                    return (
                        <div className="new-nom-suggestion" key={index} onClick={() => selectSuggestedNom(nom)}>
                            <div className="nom-title">
                                {nomTypeIcons[index]}
                                <h1>{nom.name}</h1>
                                <div className="nom-plus">
                                    <span className="material-symbols-outlined">add</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <hr className="new-nom-hr" />
            <div className="new-nom-body">
                {/* Input field for Nom Name */}
                <div className="new-nom-body-row">
                    <div className="new-nom-body-row-label">
                        Nom Name
                    </div>
                    <div className="new-nom-body-row-input">
                        <input type="text" className="new-nom-text-input" value={nomName} onChange={(e) => handleInputChange(e, setNomName)} />
                    </div>
                </div>

                {/* Input field for Time of Consumption */}
                <div className="new-nom-body-row">
                    <div className="new-nom-body-row-label">
                        Time of Consumption
                    </div>
                    <div className="new-nom-body-row-input">
                        <input type="time" className="new-nom-time-input" value={nomTime}
                            onChange={(e) => handleInputChange(e, setNomTime)} />
                    </div>
                </div>

                {/* Radio selectors for Type */}
                <RadioSelector
                    label="Type"
                    options={[
                        { value: 'food', label: 'Food', icon: 'lunch_dining', color: 'var(--clr-nom-food)' },
                        { value: 'drink', label: 'Drink', icon: 'local_drink', color: 'var(--clr-nom-drink)' }
                    ]} value={nomType}
                    onSelectionChange={(selectedType) => setNomType(selectedType)}
                    onChange={(e) => handleInputChange(e, setNomType)}
                />

                <hr className="new-nom-hr" />

                {/* Button to submit the form */}
                <div className="new-nom-footer">
                    <ActionButton buttonText={'Add Nom'} buttonFunction={handleSubmit} />
                </div>
            </div>
        </div>
    );
};

export default NewNom;
