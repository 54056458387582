import React, { useState } from 'react';
import AppController from './components/Pages/AppController.js';
import './App.css';
import { UserProvider } from './components/Contexts/UserContext/UserContext.js';
import { PageProvider } from './components/Contexts/PageContext/PageContext.js';

function App() {
  return (
    <div className="App">
      {/* Moved stylesheets to the top and recommend moving them to index.html later */}
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Rowdies:wght@700&display=swap" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,0" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
      <PageProvider page={'login'}>
        <UserProvider>
          <AppController />
        </UserProvider>
      </PageProvider>
    </div>
  );
}

export default App;
