import React from 'react';
import { signInWithPopup, getAuth, GoogleAuthProvider } from "firebase/auth";
import './GoogleSignInButton.css';
import { useUserContext } from '../../Contexts/UserContext/UserContext.js';

const provider = new GoogleAuthProvider();

// This component is a button that allows the user to sign in with Google
const GoogleSignInButton = ({ buttonText }) => {
    const { setUser } = useUserContext();

    // This function is called when the user clicks the "Sign in with Google" button
    const handleGoogleSignIn = () => {
        const auth = getAuth();

        signInWithPopup(auth, provider)
            .then((result) => {
                const user = result.user;
                setUser(user.uid);
                console.log("Logging in user with Google: " + user.uid);
            }).catch((error) => {
                console.log('Error signing in with Google!', error);
            });
    };
    return (
        <button onClick={handleGoogleSignIn} className="google-signin-button">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 488 512"><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" /></svg>
            {buttonText}
        </button>
    );
};

export default GoogleSignInButton;
