import React from 'react'
import './NomActionButton.css'

const NomActionButton = ({ buttonFunction, buttonText, buttonTextIcon, buttonColorClass }) => {
    return (
        < button className={"nom-action-button " + buttonColorClass} onClick={buttonFunction}>
            {buttonTextIcon ? <span className="material-symbols-outlined">{buttonTextIcon}</span> : ''}
            {buttonText}
        </button >
    )
}

export default NomActionButton